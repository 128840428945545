/**
 * FooterSimple produces a footer containing a logo, a description, and
 * a set of links.
 * Supported props: src localizerfn
 */
import React from 'react';
import LinkMenu from './LinkMenu';
import { Link } from 'gatsby';
import logo from '../images/logo_footer.png';

export default function Footer(props) {
  const cfg = props.src;
  const footlinks = cfg.toplinks.concat(cfg.links);
  const localized = props.localizerfn;
  const rootStyle = (cfg.bg ? `footer bg-${cfg.bg}` : 'footer');

  return (
    <footer className={rootStyle}>
      <div id="footer-logo">
        <Link to={localized('/')}>
          <img src={logo} alt={cfg.sitename} className="img-responsive" />
        </Link>
      </div>
      <div className="footer-content row">
        <div className="footer-links col-sm-4 col-md-5">
          <LinkMenu links={footlinks} itemStyle="nav-item text-white" localizerfn={localized} />
        </div>
        <div className="footer-desc col-sm-8 col-md-7 text-white">{cfg.text}</div>
      </div>
    </footer>
  );
};
